html {
	-webkit-text-size-adjust: 100%;
}
  
body {
	margin:0;
	padding:0;
}

html, body {
	overflow: auto !important;
}

a {
	outline: 0;
	text-decoration: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	cursor: pointer;
}

img {
	outline : none;
}

b,
strong {
	font-weight: 800;
}

p {
	margin: 0;
}

* {
	text-rendering: optimizeLegibility !important;
	-webkit-font-smoothing: antialiased !important;
	-moz-osx-font-smoothing: grayscale !important;
}

.hide,
.hidden {
	display: none !important;
}

// Sofia Pro
@font-face {
	font-family: 'Sofia Pro';
	font-display: auto;
	src: url('/assets/fonts/sofiapromedium.eot');
	src: url('/assets/fonts/sofiapromedium.eot?#iefix') format('embedded-opentype'),
	url('/assets/fonts/sofiapromedium.woff2') format('woff2'),
	url('/assets/fonts/sofiapromedium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Sofia Pro';
	font-display: auto;
	src: url('/assets/fonts/sofiaprosemibold.eot');
	src: url('/assets/fonts/sofiaprosemibold.eot?#iefix') format('embedded-opentype'),
	url('/assets/fonts/sofiaprosemibold.woff2') format('woff2'),
	url('/assets/fonts/sofiaprosemibold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Sofia Pro';
	font-display: auto;
	src: url('/assets/fonts/sofiaprobold.eot');
	src: url('/assets/fonts/sofiaprobold.eot?#iefix') format('embedded-opentype'),
	url('/assets/fonts/sofiaprobold.woff2') format('woff2'),
	url('/assets/fonts/sofiaprobold.woff') format('woff');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'Material Icons';
	font-style: normal;
	font-display: auto;
	font-weight: 400;
	src: url('/assets/fonts/material-icons/MaterialIcons-Regular.eot'); /* For IE6-8 */
	src: local('Material Icons'),
	local('MaterialIcons-Regular'),
	url('/assets/fonts/material-icons/MaterialIcons-Regular.woff2') format('woff2'),
	url('/assets/fonts/material-icons/MaterialIcons-Regular.woff') format('woff'),
	url('/assets/fonts/material-icons/MaterialIcons-Regular.ttf') format('truetype');
}
